import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
//components
import Card from '@/components/Card/Card.vue';
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import LabelList from '@/components/Label/LabelList/LabelList.vue';
import AgreeDisagreeCount from '@/components/Engagements/AgreeDisagreeCount/AgreeDisagreeCount.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let StoryCard = class StoryCard extends Vue {
    async click() {
        await this.$store.dispatch('stories/setActiveStory', this.story);
        this.$router.push({ path: `/story/${this.story.slug}` });
    }
    handleClickEvent(e) {
        this.$emit("topic-changed", e);
    }
    getCoverImage() {
        if (this.story.hero_image && this.story.hero_image.public_url) {
            return this.story.hero_image.public_url;
        }
        else {
            return '';
        }
    }
    getCoverImageCopyright() {
        if (this.story.hero_image && this.story.hero_image.public_url && this.story.hero_image_copyright) {
            return this.story.hero_image_copyright;
        }
        else {
            return '';
        }
    }
    async pushHistoryState(url) {
        await history.pushState({ search: this.$store.state.stories.searchTerm, page: this.$store.state.stories.page }, 'Stories', this.$route.fullPath);
        this.$router.push({ path: url });
    }
};
__decorate([
    Prop()
], StoryCard.prototype, "story", void 0);
StoryCard = __decorate([
    Component({
        components: { Card, ProfileCard, LabelList, AgreeDisagreeCount, MarkdownFormatter },
    })
], StoryCard);
export default StoryCard;
